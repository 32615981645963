<template>
  <div class="player-view-closer">
     <div class="content-details">
         <div class="title">{{content.title}}</div>
         <div class="description">
             <span v-for='(tag,id) in tags' :key="id">{{tag}}</span>
         </div>
     </div>     
  </div>
</template>

<script>
export default {
    props:{
      content:{
          type:Object,
          default(){
              return {}
          }
      }  
    },
    computed:{
        tags(){
            return this.content.tags??[];
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "~sass/modules/_variables";
    @import "~sass/modules/_fonts";
     .player-view-closer{
         height:max-content;
         position:absolute;
         bottom:calc( var(--player-controlbar-height) + 2rem );
         left:calc( calc( 100% - var(--player-controls-width)) / 2 );
         display:flex;
         flex-flow:row nowrap;
         justify-content: flex-start;
         align-items: center;
        .closer-block{
            height:max-content;
            cursor:pointer;
           .closer-image{
               display:block;
               height:2rem;
               transition:transform 0.1s;
                &:hover{
                    transform: scale(1.1);
                }
                &:active{
                    transform: scale(0.9);
                }
           }
        }
        .content-details{
            color: $clr-white;
            text-transform:uppercase;
            text-align:left;
            opacity:0.8;
            .title{
                font-size: 1.5rem;
                line-height:2rem;
                height:2rem;
                font-family: $font-family;
                font-weight: $font-weight-semibold;
                overflow: hidden;
                margin-bottom:0.5rem;
            }
            .description span{
                margin-right:0.7rem;
                font-size:0.9rem;
                font-weight: $font-weight-regular;
            }
        }
     }     
</style>